import * as React from "react"
import { Link } from "gatsby"
import LogoRobinsMorton from "./logo-robins-morton"
import LogoStudioByTheTracks from "./logo-studio-by-the-tracks"

const Header = () => (
  <header className="container flex justify-between py-10">
    <Link to="/" className="block text-center w-52">
      <LogoRobinsMorton />
      <span className="block my-3 text-2xl font-bold">+</span>
      <LogoStudioByTheTracks />
    </Link>

    <label
      htmlFor="nav-drawer"
      className="font-bold tracking-wider uppercase transition-colors hover:text-brand"
    >
      Menu
    </label>
  </header>
)

export default Header
