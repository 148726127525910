import * as React from "react"

const Footer = () => (
  <footer className="container py-20 text-sm">
    <span>
      &copy; {new Date().getFullYear()}{" "}
      <a
        href="https://robinsmorton.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Robins &amp; Morton
      </a>
      . All rights reserved.
    </span>{" "}
    <a
      href="https://kinetic.com"
      target="_blank"
      rel="noopener noreferrer"
      title="Web Development Agency located in Birmingham, Alabama"
    >
      Developed by Kinetic.
    </a>
  </footer>
)

export default Footer
