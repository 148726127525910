import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

const NavMenu = () => {
  const data = useStaticQuery(graphql`
    query NAV_MENU {
      posts: allMdx(sort: { fields: [frontmatter___orderBy], order: ASC }) {
        nodes {
          ...PostData
        }
      }
    }
  `)

  return (
    <nav className="drawer-side">
      <label htmlFor="nav-drawer" className="drawer-overlay"></label>
      <ul className="w-full max-w-sm p-10 bg-white">
        <li className="mb-10 text-right">
          <label
            htmlFor="nav-drawer"
            className="font-bold tracking-wider uppercase transition-colors hover:text-brand"
          >
            Close
          </label>
        </li>
        {data.posts.nodes.map(({ frontmatter, fields }) => (
          <li key={fields.slug}>
            <Link to={fields.slug}>{frontmatter.title}</Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default NavMenu
